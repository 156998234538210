/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/**
 * InventoryMenu
 *
 * Component that subscribes to firestoree and displays the current dispensaries inventory menu
 */

// externals
import React, { useRef, useEffect, useState } from 'react'
import { string, bool, func } from 'prop-types'
import { css } from '@emotion/core'
import { Box } from '@rebass/emotion'
import Loader from 'components/Loader'
import { inventoryQuery, pricesQuery } from 'wrFirebase/inventoryActions'
import { COLORS, SPACERS } from 'utils/styleHelpers'
import { NoMenuItems, Emoji } from './styles'
import { NODE_TYPES } from 'utils/constants'
import { sortBy2Keys } from 'utils/arrayHelpers'
import { toSnakeCase } from 'utils/stringUtils'
import { Masonry } from 'masonic'
import InventoryItemRow from './InventoryItemRow'
import StaticInventoryMenu from './StaticInventoryMenu'
import { LazyLoadWrapper, MenuContainer, FilterButtonGroup, FilterButton } from './styles'
import { useCurrentBreakpointName } from 'react-socks'

import WithMenuItemMetaData from './WithMenuItemMetaData'
function InventoryMenu({ isLazyLoaded = true, nodeType, nodeId, productId = 'none', setMenuItems = () => true }) {
	const breakpoint = useCurrentBreakpointName()
	const isMobile = breakpoint === 'XS' || breakpoint === 'S'
	const cellWidthMobile = 180
	const cellWidthDesktop = 230
	const spacer = 5
	const columnWidth = isMobile ? cellWidthMobile : cellWidthDesktop
	const selectedProductId = useRef(productId)
	const unSubscribeInventory = useRef(null)
	const unSubscribePrices = useRef(null)

	const [availableFilters, setAvailableFilters] = useState([])
	const [filteredMenuList, setFilteredMenuList] = useState([])
	const [inventoryList, setInventoryList] = useState([])
	const [inventoryItemsChanging, setInventoryItemsChanging] = useState([])
	const [isLoading, setIsLoading] = useState(true)
	const [priceList, setPriceList] = useState([])
	const [selectedFilter, setSelectedFilter] = useState(true)
	// Utils
	function createFnCounter(fn, invokeBeforeExecution) {
		let count = 0
		return args => {
			count++
			if (count <= invokeBeforeExecution) {
				return true
			} else {
				return fn(args, count)
			}
		}
	}
	// Firestore Subcription managment functions
	const handleInventorySubWithCounter = createFnCounter(handleInventorySubInvocation, 0)
	const handlePricesSubWithCounter = createFnCounter(handlePricesSubInvocation, 0)
	function handlePricesSubInvocation(snapshot, counter) {
		if (!snapshot.empty) {
			const intialLoad = counter === 1

			if (!intialLoad) {
				const changingInventory = []

				snapshot.docChanges().forEach(changes => {
					const { inventory } = changes.doc.data()
					changingInventory.push(inventory)
				})
				setInventoryItemsChanging(changingInventory)
			}

			const unsortedPrices = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
			setPriceList(unsortedPrices)
		}
		setIsLoading(false)
	}
	function handleInventorySubInvocation(snapshot, counter) {
		if (!snapshot.empty) {
			const intialLoad = counter === 1
			if (!intialLoad) {
				const changingInventory = []
				snapshot.docChanges().forEach(changes => {
					const { id } = changes.doc
					changingInventory.push(id)
				})
				setInventoryItemsChanging(changingInventory)
			}
			const sortedItems = sortBy2Keys(
				snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })),
				'category',
				'productName'
			)
			// pull out the unique categories for use as filters
			const uniqueCatsSet = new Set(sortedItems.map(item => item.category))
			setAvailableFilters(Array.from(uniqueCatsSet))
			if (nodeType === NODE_TYPES.STRAINS) {
				setIsLoading(false)
			}
			if (productId !== 0) {
				sortedItems.sort((item1, item2) => (item1.id === productId ? -1 : item2.id === productId ? 1 : 0))
			}
			setInventoryList(
				sortedItems.map(f => ({
					...f,
					prices: priceList.filter(p => p.dispensary === f.dispensary && f.id === p.inventory),
				}))
			)

			if (nodeType === NODE_TYPES.STRAINS) {
				setIsLoading(false)
			}
		}
		if (nodeType === NODE_TYPES.STRAINS) {
			setIsLoading(false)
		}
	}
	// Firestore subscription init functions to be used in useEffect
	async function subscribeInventory() {
		return await inventoryQuery(nodeId, nodeType).onSnapshot(handleInventorySubWithCounter)
	}
	async function subscribePrices() {
		if (nodeType === NODE_TYPES.STRAINS) {
			return () => true
		}
		return await pricesQuery(nodeId).onSnapshot(handlePricesSubWithCounter)
	}

	useEffect(() => {
		async function subscribeMenu() {
			unSubscribeInventory.current = await subscribeInventory()
			unSubscribePrices.current = await subscribePrices()
		}
		subscribeMenu()
		return () => {
			unSubscribeInventory.current()
			unSubscribePrices.current()
		}
	}, [])

	useEffect(() => {
		setFilteredMenuList(
			inventoryList.map(i => ({
				...i,
				prices: priceList.filter(p => p.dispensary === i.dispensary && i.id === p.inventory),
			}))
		)
	}, [inventoryList, priceList])

	useEffect(() => {
		let filteredMenu = []
		if (!selectedFilter) {
			filteredMenu = inventoryList.map(i => ({
				...i,
				prices: priceList.filter(p => p.dispensary === i.dispensary && i.id === p.inventory),
			}))
		} else {
			filteredMenu = inventoryList
				.filter(l => l.category === selectedFilter)
				.map(i => ({
					...i,
					prices: priceList.filter(p => p.dispensary === i.dispensary && i.id === p.inventory),
				}))
		}
		setFilteredMenuList(filteredMenu)
	}, [selectedFilter])

	useEffect(() => {
		setMenuItems(filteredMenuList)
	}, [filteredMenuList])

	return (
		<MenuContainer flexDirection="column">
			{availableFilters.length > 0 && (
				<Box>
					{/* filters to sort menu items by */}
					<FilterButtonGroup
						flexWrap="wrap"
						justifyContent="center"
						alignItems="space-between"
						flexDirection="row"
					>
						{availableFilters.map(filter => {
							const isSelected = filter === selectedFilter
							return (
								<Box
									key={toSnakeCase(filter)}
									css={css`
										margin: ${SPACERS.S};
									`}
								>
									<FilterButton
										key={filter}
										isSelected={isSelected}
										onClick={() => {
											if (filter === selectedFilter) {
												setSelectedFilter(null)
											} else {
												setSelectedFilter(filter)
											}
										}}
										type="button"
									>
										{filter}
									</FilterButton>
								</Box>
							)
						})}
					</FilterButtonGroup>
				</Box>
			)}

			{isLoading && (
				<Box>
					<Loader />
				</Box>
			)}

			{!isLoading && filteredMenuList.length === 0 && nodeType === NODE_TYPES.DISPENSARIES && (
				<NoMenuItems>
					<Box
						css={css`
							padding: ${SPACERS.L};
						`}
					>
						<Emoji ariaLabel="grinning emoji" role="img">
							😬
						</Emoji>{' '}
						We&apos;re sorry, but we don&apos;t have a menu for this dispensary yet.{' '}
					</Box>
				</NoMenuItems>
			)}
			{!isLoading && filteredMenuList.length === 0 && nodeType === NODE_TYPES.STRAINS && (
				<NoMenuItems>
					<Box
						css={css`
							padding: ${SPACERS.L};
						`}
					>
						<Emoji ariaLabel="grinning emoji" role="img">
							😬
						</Emoji>{' '}
						We&apos;re sorry, but it looks like there are no products for this strain yet.
					</Box>
				</NoMenuItems>
			)}

			{filteredMenuList && !!filteredMenuList.length && (
				<Box
					css={css`
						background: ${COLORS.LIGHT_GRAY};
						transition: background 0.5s;
						.dark & {
							background: ${COLORS.BLACK};
						}
					`}
				>
					<WithMenuItemMetaData.Provider
						value={{
							inventoryItemsChanging,
							setInventoryItemsChanging,
							selectedProductId: selectedProductId.current,
							nodeType,
						}}
					>
						{isLazyLoaded ? (
							<LazyLoadWrapper islazyloaded={isLazyLoaded.toString()}>
								<Masonry
									key={`masonry_${selectedFilter ? toSnakeCase(selectedFilter.toString()) : 'none'}`}
									items={filteredMenuList}
									columnGutter={spacer}
									columnWidth={columnWidth}
									overscanBy={10}
									render={InventoryItemRow}
								/>
							</LazyLoadWrapper>
						) : (
							<>
								{!isLoading && (
									<LazyLoadWrapper islazyloaded={isLazyLoaded.toString()}>
										<StaticInventoryMenu menuItems={filteredMenuList} />
									</LazyLoadWrapper>
								)}
							</>
						)}
					</WithMenuItemMetaData.Provider>
				</Box>
			)}
		</MenuContainer>
	)
}

InventoryMenu.propTypes = {
	isLazyLoaded: bool,
	nodeType: string.isRequired,
	nodeId: string,
	productId: string,
	setMenuItems: func,
}

export default InventoryMenu
