import styled from '@emotion/styled'
import { COLORS, FONT_SIZES, SPACERS, MQ } from 'utils/styleHelpers'

const CloseButton = styled.button`
	background: ${COLORS.RED};
	border: 1px solid ${COLORS.RED};
	border-radius: 4px;
	color: ${COLORS.WHITE};
	font-size: ${FONT_SIZES.H5};
	font-weight: 700;
	width: 100%;
	text-align: center;
	padding-right: ${SPACERS.M};
	padding-left: ${SPACERS.M};
	padding-top: ${SPACERS.M};
	padding-bottom: ${SPACERS.M};
	${MQ.L} {
		font-size: ${FONT_SIZES.H7};
		padding-right: ${SPACERS.L};
		padding-left: ${SPACERS.L};
		width: 200px;
	}
`

export default CloseButton
