import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from '@rebass/emotion'
import ShowMore from 'react-show-more'
import { ThemeProvider } from 'emotion-theming'
import { Link } from 'gatsby'
import BottomDrawer from 'components/BottomDrawer'
import ReviewForm from 'components/ReviewForm'
import fullTheme from 'components/ReviewsList/ReviewFullTheme'
import ReviewsList from 'components/ReviewsList'
import StickyAd from 'components/StickyAd'
import InventoryMenu from 'components/InventoryMenu'
import Tabs, { Tab, TabList, TabPanel, TabPanels } from 'components/Tabs'
import { formatPhone } from 'utils/stringUtils'
import { authConditionReviewer } from 'utils/securityHelpers'
import { TRACKING_ACTIONS, EVENT_TYPES, NODE_TYPES, DISPENSARY_TABS } from 'utils/constants'
import { TrackingPropType } from 'react-tracking'
import { SessionContext } from 'components/AuthContext'
import useAuthorization from 'hooks/useAuthorization'
import {
	AboutWrapper,
	HeightSpacerBox,
	HeightSpacerBoxSmall,
	InfoLabel,
	InfoValue,
	InventoryMenuWrapper,
	NoAuthForReview,
	ShowMoreBox,
} from './styles'
function DispensaryTabs({
	about,
	activeTabIndex,
	address1,
	address2,
	city,
	hours,
	isMobile,
	id,
	name,
	phone,
	productId = null,
	reviews,
	state,
	slug,
	tracking,
	zip,
}) {
	const activeIndex = !activeTabIndex ? (isMobile ? DISPENSARY_TABS.ABOUT : DISPENSARY_TABS.REVIEWS) : activeTabIndex
	const phoneFormatted = phone ? formatPhone(phone, '(NNN) NNN-NNNN') : ''
	const { sessionID, uid, userIp, userLocation, version } = useContext(SessionContext)
	const { isAuthorized } = useAuthorization(authConditionReviewer)
	const trackingData = {
		nodeID: id,
		nodeType: NODE_TYPES.DISPENSARIES,
		nodeState: state,
		nodeCity: city,
		nodeZip: zip,
		nodeLabel: name,
		sessionID,
		uid,
		userIp,
		userLocation,
		version,
	}
	return (
		<Tabs activeIndex={activeIndex}>
			<TabList>
				{isMobile && <Tab>About</Tab>}
				<Tab
					onClick={() =>
						tracking.trackEvent({
							action: TRACKING_ACTIONS.VIEW_ACTION,
							event: EVENT_TYPES.WR_DISPENSARY_REVIEWS_VIEW,
							...trackingData,
						})
					}
				>
					Reviews
				</Tab>
				<Tab
					onClick={() =>
						tracking.trackEvent({
							action: TRACKING_ACTIONS.VIEW_ACTION,
							event: EVENT_TYPES.WR_DISPENSARY_MENU_VIEW,
							...trackingData,
						})
					}
				>
					Menu
				</Tab>
			</TabList>
			<TabPanels>
				<TabPanel>
					{isMobile && (
						<>
							<AboutWrapper flexDirection="column" justifyContent="center">
								<Box>
									<Flex flexDirection="column">
										<Box>
											<InfoLabel>Hours</InfoLabel>: <InfoValue>{hours}</InfoValue>
										</Box>
										<Box>
											<InfoLabel>Phone</InfoLabel>: <InfoValue>{phoneFormatted}</InfoValue>
										</Box>
										<Box>
											<InfoLabel>Address</InfoLabel>:{' '}
											<InfoValue>
												{address1} {address2} {city}, {state}
											</InfoValue>
										</Box>
									</Flex>
								</Box>
								<ShowMoreBox>
									<ShowMore less="Show less" lines={8} more="Show more">
										{about}
									</ShowMore>
								</ShowMoreBox>
								<HeightSpacerBox />
							</AboutWrapper>
							<StickyAd />
						</>
					)}
				</TabPanel>

				<TabPanel>
					<Flex flexDirection="column">
						<Box>
							<ThemeProvider theme={fullTheme}>
								<ReviewsList reviews={reviews} />
							</ThemeProvider>
						</Box>
						<HeightSpacerBox />
						{isMobile && (
							<Box>
								{isAuthorized ? (
									<Box>
										<BottomDrawer>
											<ReviewForm
												nodeCity={city}
												nodeID={id}
												nodeLabel={name}
												nodeState={state}
												nodeType={NODE_TYPES.DISPENSARIES}
												nodeZip={zip}
												slug={slug}
											/>
										</BottomDrawer>
									</Box>
								) : (
									<NoAuthForReview>
										<Link to="/login">Login to leave your review!</Link>
									</NoAuthForReview>
								)}
							</Box>
						)}
					</Flex>
				</TabPanel>
				<TabPanel>
					<InventoryMenuWrapper>
						<InventoryMenu nodeId={id} nodeType={NODE_TYPES.DISPENSARIES} productId={productId} />
					</InventoryMenuWrapper>
					<HeightSpacerBoxSmall />
				</TabPanel>
			</TabPanels>
		</Tabs>
	)
}

DispensaryTabs.propTypes = {
	activeTabIndex: PropTypes.number,
	about: PropTypes.string,
	address1: PropTypes.string,
	address2: PropTypes.string,
	city: PropTypes.string,
	hours: PropTypes.string,
	isMobile: PropTypes.bool,
	id: PropTypes.string,
	name: PropTypes.string,
	phone: PropTypes.string,
	productId: PropTypes.string,
	reviews: PropTypes.array,
	state: PropTypes.string,
	slug: PropTypes.string,
	tracking: TrackingPropType,
	zip: PropTypes.string,
}

export default DispensaryTabs
