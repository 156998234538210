import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Drawer } from './styles';
import CloseButton from 'components/CloseButton';
import { Flex, Box } from '@rebass/emotion';
import { css } from '@emotion/core';
import { SPACERS } from 'utils/styleHelpers';

function BottomDrawer({
	children,
	endBottom = '0',
	startBottom = '-30%'
}) {
	const [isOpen, setIsOpen] = useState(false);
	const buttonMessage = isOpen ? 'Close' : '★ Leave a Review';
	return <>
		<Drawer endBottom={endBottom} startBottom={startBottom} isOpen={isOpen}>
			<Flex flexDirection="column">
				<Box css={css`
							width: 100%;
							padding: ${SPACERS.S};
						`}>
					<CloseButton isOpen={isOpen} onClick={() => setIsOpen(!isOpen)}>
						{buttonMessage}
					</CloseButton>
				</Box>
				<Box>{children}</Box>
			</Flex>
		</Drawer>
	</>;
}

BottomDrawer.propTypes = {
	endBottom: PropTypes.string,
	startBottom: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
};
export default BottomDrawer;