import styled from '@emotion/styled'
import { COLORS, MQ, SPACERS, FONT_SIZES } from 'utils/styleHelpers'
import { Box, Flex } from '@rebass/emotion'

export const NodePageWrapper = styled.div`
	display: flex;
	flex-direction: column;
	${MQ.L} {
		margin-top: ${SPACERS.L};
		flex-direction: row;
	}
`
export const HeaderInfoBottomBoxLarge = styled(Box)`
	background: ${COLORS.WHITE};
	padding: ${SPACERS.L};
	font-size: ${FONT_SIZES.H5};
	transition: background 0.5s;
	.dark & {
		background: ${COLORS.MEDIUM_GRAY};
	}
`

export const HeaderInfoBox = styled(Box)`
	width: 100%;
`
export const HeaderInfoLeftBox = styled(Box)`
	margin-top: ${SPACERS.L};
`

export const InfoWrapper = styled.div`
	${MQ.L} {
		margin-right: ${SPACERS.L};
		width: 40%;
	}
`
export const AdColumnBox = styled(Box)`
	padding-top: ${SPACERS.L};
	text-align: center;
`
export const AdColumnHeadline = styled.span`
	font-weight: 700;
`
export const RightColumnBox = styled(Box)`
	margin-top: ${SPACERS.L};
	margin-bottom: ${SPACERS.L};
	width: 100%;
	${MQ.L} {
		width: 75%;
	}
`

export const TopCard = styled.div`
	background-image: url('https://storage.googleapis.com/images.weedrater.com/strain-images/defaultStrainImage.png');
	background-position: center center;
	background-size: cover;
	border-radius: 0 ${SPACERS.S} ${SPACERS.S} 0;
	min-height: 200px;
	padding-top: ${SPACERS.L};
`

export const TopText = styled.div`
	background: rgba(0, 0, 0, 0.4);
	color: #fff;
	font-size: ${FONT_SIZES.H3};
	font-weight: bold;
	padding: ${SPACERS.M};
	text-align: right;
`

export const AboutCard = styled.div`
	border: 1px solid ${COLORS.GREEN};
	box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 4px 8px 0 rgba(0, 0, 0, 0.19);
	border-radius: 0 0 ${SPACERS.S} ${SPACERS.S};
	color: #333;
	font-size: 16px;
	line-height: 21px;
	padding: ${SPACERS.L};
`

export const AddressLink = styled.a`
	color: #83f7c5;
	transition: all 0.5s;
	text-decoration: underline dotted;

	&:hover {
		color: #fff;
	}
`

export const TabWrapper = styled.div`
	margin-top: ${SPACERS.M};

	${MQ.L} {
		margin-top: 0;
		width: 60%;
	}
`

export const NoAuthForReview = styled.div`
	margin-top: ${SPACERS.L};
	text-align: center;
`

export const NodeLayoutWrapper = styled(Flex)`
	padding-top: ${SPACERS.M};
	background: ${COLORS.WHITE};
	transition: background 0.5s;
	${MQ.L} {
		background: ${COLORS.LIGHT_GRAY};
	}
	.dark & {
		background: ${({ isMobile }) => (isMobile ? COLORS.MEDIUM_GRAY : COLORS.BLACK)};
	}
`
export const NodeLayoutWrapperBox = styled(Box)`
	margin: ${SPACERS.M};
	transition: background 0.5s;
	${MQ.L} {
		width: 75%;
	}
	.dark & {
		background: ${COLORS.BLACK};
	}
`
export const HeaderContainer = styled(Flex)`
	padding: 0;
	background: none;
	transition: background 0.5s;
	background: ${COLORS.WHITE};
	${MQ.L} {
		padding-top: ${SPACERS.XL};
		padding-right: ${SPACERS.XL};
		padding-left: ${SPACERS.XL};
		padding-bottom: ${SPACERS.M};
	}
	.dark & {
		background: ${COLORS.MEDIUM_GRAY};
	}
`

export const AvatarWrapper = styled(Box)`
	margin-right: ${SPACERS.S};
	text-align: center;
`
export const HeaderWrapper = styled(Box)`
	padding-right: ${SPACERS.S};
`

export const NodeName = styled.span`
	font-size: ${FONT_SIZES.H4};
	font-weight: 500;
	color: ${COLORS.GREEN};
	${MQ.L} {
		font-size: ${FONT_SIZES.H2};
		font-weight: 700;
	}
`
export const InfoLabel = styled.span`
	font-size: ${FONT_SIZES.H6};
	font-weight: 600;
	${MQ.L} {
		font-size: ${FONT_SIZES.H5};
	}
`
export const NodeValue = styled.span`
	font-size: ${FONT_SIZES.H6};
	${MQ.L} {
		font-size: ${FONT_SIZES.H5};
	}
`
