import { func, node, number } from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'

const propTypes = {
	_activeIndex: number,
	_onTabSelect: func,
	children: node.isRequired,
}

const defaultProps = {
	_activeIndex: 0,
}

const DefaultTabList = styled.div`
	display: flex;
	justify-content: space-between;
`

function TabList({ children, _activeIndex, _onTabSelect }) {
	return (
		<DefaultTabList>
			{React.Children.map(children, (child, index) => {
				return child
					? React.cloneElement(child, {
							_isActive: index === _activeIndex,
							_onSelect: () => {
								_onTabSelect(index)
								!!child.props.onClick && child.props.onClick()
							},
					  })
					: null
			})}
		</DefaultTabList>
	)
}

TabList.displayName = 'TabList'
TabList.defaultProps = defaultProps
TabList.propTypes = propTypes

export default TabList
