import { bool, node, number } from 'prop-types'
import React from 'react'

const propTypes = {
	_activeIndex: number,
	children: node.isRequired,
	shouldStayMounted: bool,
}

const defaultProps = {
	_activeIndex: 0,
	shouldStayMounted: false,
}

/* eslint no-underscore-dangle: 0 */
function TabPanels({ children, _activeIndex, shouldStayMounted }) {
	if (shouldStayMounted) {
		return (
			<div>
				{React.Children.map(children, (child, index) => {
					return child
						? React.cloneElement(child, {
								_isActive: index === _activeIndex,
						  })
						: null
				})}
			</div>
		)
	}

	return <div>{React.Children.toArray(children)[_activeIndex]}</div>
}

TabPanels.displayName = 'TabPanels'
TabPanels.defaultProps = defaultProps
TabPanels.propTypes = propTypes

export default TabPanels
