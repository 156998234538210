import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Form, Field } from 'react-final-form'
import { css } from '@emotion/core'
import { Flex, Box } from '@rebass/emotion'
import axios from 'axios'
import * as firebase from 'firebase/app'

import StarRating from 'components/StarRating'
import Loader from 'components/Loader'
import { InputFieldFF, TextAreaField } from 'components/FormFields'
import { ErrorMessage, StyledButton } from 'components/FormStyles'
import { Star } from 'components/Iconography'

import track, { TrackingPropType } from 'react-tracking'
import { apiReviewsURL } from 'utils/apiHelpers'

// import { Box, Flex, FlexInner, FormWrapper } from 'components/FormLayout'
import { BUTTON_MX, BUTTON_MY, BUTTON_WIDTH } from 'utils/formHelpers'

import { COLORS, FONT_SIZES, SPACERS } from 'utils/styleHelpers'
import { EVENT_TYPES, TRACKING_ACTIONS } from 'utils/constants'
import { stampTime, eventDispatch } from 'utils/tracker'
import { SessionContext } from 'components/AuthContext'

const ConfirmationFlex = styled.div`
	display: flex;
	color: ${COLORS.GREEN};
	flex-direction: column;
	font-weight: bold;
	font-size: ${FONT_SIZES.H3};
	justify-content: center;
	text-align: center;
	min-height: 300px;
`
const FormHeader = styled.label`
	color: ${COLORS.BLACK};
	text-transform: uppercase;
	transition: color 0.5s;
	.dark & {
		color: ${COLORS.WHITE};
	}
`
function ReviewForm({ nodeCity, nodeID, nodeLabel, nodeState, nodeType, nodeZip, slug, strainCategory, tracking }) {
	const { sessionID, uid, userIp, userLocation, version } = useContext(SessionContext)
	const [responseText, setResponseText] = useState('')
	const [hasResponseError, setHasResponseError] = useState(false)
	const [hasSubmitted, setHasSubmitted] = useState(false)
	const [ratingValue, setRatingValue] = useState(0)

	const validate = values => {
		const errors = {}
		if (!values.summary) {
			errors.summary = `Please add a summary for your review`
		}
		if (!values.review) {
			errors.review = `Please add a complete review.`
		}
		if (!values.rating || values.rating === 0) {
			errors.rating = `Please give a rating of 1 to 5 stars`
		}
		return errors
	}
	const onSubmit = async values => {
		const rating = values.rating
		const reviewPayload = {
			reviewNodeID: nodeID,
			reviewText: values.review,
			reviewTitle: values.summary,
			nodeName: nodeLabel,
			slug,
			state: nodeState || null,
			city: nodeCity || null,
			category: strainCategory || null,
			reviewType: nodeType,
			rating,
			isApproved: false,
			userID: uid,
			zip: nodeZip || null,
		}
		const dateCreated = new Date(Date.now())
		const token = await firebase.auth().currentUser.getIdToken(true)
		const config = {
			headers: { Authorization: `Bearer ${token}` },
		}

		try {
			const postResponse = await axios.post(apiReviewsURL, { ...reviewPayload, dateCreated }, config)
			setResponseText(postResponse.data)
			setHasSubmitted(true)
			setRatingValue(0)

			tracking.trackEvent({
				action: TRACKING_ACTIONS.RATING_ACTION,
				event: EVENT_TYPES.WR_NODERATED,
				nodeCity,
				nodeID,
				nodeLabel,
				nodeState,
				nodeZip,
				nodeType,
				rating,
				sessionID,
				strainCategory,
				uid,
				userIp,
				userLocation,
				version,
			})
		} catch (error) {
			setHasResponseError(true)
			setResponseText(error.response.data)
			setHasSubmitted(true)
			setRatingValue(0)
		}
	}
	const onStarClick = nextValue => {
		setRatingValue(nextValue)
	}

	return (
		<Form onSubmit={onSubmit} validate={validate}>
			{({
				handleSubmit, // injected from final-form-arrays above
				errors,
				submitFailed,
				submitting,
			}) => {
				if (submitting) {
					return <Loader />
				}
				return (
					<>
						{!hasSubmitted ? (
							<form onSubmit={handleSubmit}>
								<Flex
									justifyContent="flex-start"
									flexDirection="column"
									css={css`
										width: 100%;
									`}
								>
									<Box
										css={css`
											padding: ${SPACERS.M};
										`}
									>
										<FormHeader>Leave a Review</FormHeader>
									</Box>
									<Box
										css={css`
											padding: ${SPACERS.M};
										`}
									>
										<Field component={InputFieldFF} name="summary" placeholder="Summary" />
									</Box>
									<Box
										css={css`
											padding: ${SPACERS.M};
										`}
									>
										<Field component={TextAreaField} name="review" placeholder="Add your review" />
									</Box>
									<Box
										alignSelf="center"
										css={css`
											padding: ${SPACERS.M};
										`}
									>
										<Field name="rating">
											{({ input: { onChange, value } }) => {
												return (
													<StarRating
														emptyStarColor={COLORS.MEDIUM_LIGHT_GRAY}
														isEditing={true}
														name="ReviewratingValue"
														onChange={onChange}
														onStarClick={onStarClick}
														renderStarIcon={Star({ fontSize: FONT_SIZES.H2 })}
														starRatingId={nodeID}
														starColor={COLORS.YELLOW}
														starCount={5}
														starPadding={SPACERS.M}
														value={ratingValue}
													/>
												)
											}}
										</Field>
									</Box>
									{submitFailed && (
										<>
											<Box
												css={css`
													padding: ${SPACERS.M};
													text-aling: center;
												`}
											>
												<ErrorMessage>
													{Object.keys(errors).map(e => errors[e])[0]}
												</ErrorMessage>
											</Box>
										</>
									)}
									<Box
										css={css`
											padding: ${SPACERS.M};
										`}
									>
										<StyledButton
											type="submit"
											bg={COLORS.GREEN}
											mx={BUTTON_MX}
											my={BUTTON_MY}
											width={BUTTON_WIDTH}
										>
											Post Your Review
										</StyledButton>
									</Box>
								</Flex>
							</form>
						) : (
							<ConfirmationFlex>
								<Box
									css={css`
										margin-bottom: ${SPACERS.L};
									`}
								>
									{responseText}
								</Box>
								{hasResponseError && (
									<Box
										css={css`
											margin-top: ${SPACERS.L};
										`}
									>
										<StyledButton
											type="submit"
											bg={COLORS.GREEN}
											mx={BUTTON_MX}
											my={BUTTON_MY}
											width={BUTTON_WIDTH}
											onClick={() => setHasSubmitted(false)}
										>
											Retry
										</StyledButton>
									</Box>
								)}
							</ConfirmationFlex>
						)}
					</>
				)
			}}
		</Form>
	)
}

ReviewForm.propTypes = {
	nodeCity: PropTypes.string,
	nodeID: PropTypes.string.isRequired,
	nodeLabel: PropTypes.string,
	nodeState: PropTypes.string,
	nodeType: PropTypes.string.isRequired,
	nodeZip: PropTypes.string,
	slug: PropTypes.string,
	strainCategory: PropTypes.string,
	tracking: TrackingPropType,
}

export default track({ timestamp: stampTime() }, { dispatch: data => eventDispatch(data) })(ReviewForm)
