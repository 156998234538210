import React, { useState } from 'react'
import { Flex, Box } from '@rebass/emotion'
import { MdClear } from 'react-icons/md'
import { css } from '@emotion/core'
import AdCard from 'components/AdCard'
import StickyFooter from 'components/StickyFooter'
import { AdCardBox, AdWrapper, AdHeaderBox, AdHeader, CloseButton } from './styles'

function StickyAd() {
	const [isAdDisplayed, setAdIsDisplayed] = useState(true)
	return (
		<>
			{isAdDisplayed && (
				<>
					<StickyFooter verticalHeight="23vh">
						<CloseButton onClick={() => setAdIsDisplayed(!isAdDisplayed)}>
							<MdClear />
						</CloseButton>
						<AdWrapper justifyContent="center" alignItems="center">
							<AdHeaderBox>
								<Flex flexDirection="column" alignItems="center" justifyContent="center">
									<Box
										css={css`
											margin-top: 10px;
										`}
									>
										<AdHeader>You may also be interested in...</AdHeader>
									</Box>

									<AdCardBox>
										<AdCard />
									</AdCardBox>
								</Flex>
							</AdHeaderBox>
						</AdWrapper>
					</StickyFooter>
				</>
			)}
		</>
	)
}

export default StickyAd
