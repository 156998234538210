import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { SecondaryButton } from 'components/SecondaryButton'
import { css } from '@emotion/core'
import { Flex, Box } from '@rebass/emotion'
import track, { TrackingPropType } from 'react-tracking'
import { HeaderInfoLeftBottomBox, HeaderInfoLeftTopBox } from './styles'
import { COLORS, SPACERS, MQ } from 'utils/styleHelpers'
import { authConditionIsAdmin } from 'utils/securityHelpers'
import { stampTime, eventDispatch } from 'utils/tracker'
import { EVENT_TYPES, TRACKING_ACTIONS, NODE_TYPES } from 'utils/constants'
import { demoInventoryUpdates } from 'wrFirebase/inventoryActions'
import { SessionContext } from 'components/AuthContext'
import useAuthorization from 'hooks/useAuthorization'

function HeaderInfoLeft({ address1, address2, city, id, location, name, phone, state, tracking, zip }) {
	const { sessionID, uid, userIp, userLocation, version } = useContext(SessionContext)
	const { isAuthorized } = useAuthorization(authConditionIsAdmin)
	const trackingData = {
		action: TRACKING_ACTIONS.CALL_ACTION,
		event: EVENT_TYPES.WR_DISPENSARY_CALL,
		nodeID: id,
		nodeType: NODE_TYPES.DISPENSARIES,
		nodeState: state,
		nodeCity: city,
		nodeZip: zip,
		nodeLabel: name,
		sessionID,
		uid,
		userIp,
		userLocation,
		version,
	}
	return (
		<Flex flexDirection="row" justifyContent="flex-start" flexWrap="wrap">
			<HeaderInfoLeftTopBox>
				<SecondaryButton onClick={() => tracking.trackEvent(trackingData)} id={id} href={`tel:${phone}`}>
					Contact Now
				</SecondaryButton>
			</HeaderInfoLeftTopBox>
			<HeaderInfoLeftBottomBox>
				<SecondaryButton
					id={id}
					href={`https://www.google.com/maps/search/?api=1&query=${name}+${address1}+${address2}+${state}`}
					onClick={() => tracking.trackEvent(trackingData)}
					target="_new"
				>
					Get Directions
				</SecondaryButton>
			</HeaderInfoLeftBottomBox>
			{isAuthorized && (
				<Box
					css={css`
						padding-top: ${SPACERS.L};
						${MQ.L} {
							padding-top: 0;
						}
					`}
				>
					<SecondaryButton onClick={() => demoInventoryUpdates(id)} color={COLORS.RED}>
						Demo Menu Updates
					</SecondaryButton>
				</Box>
			)}
		</Flex>
	)
}

HeaderInfoLeft.propTypes = {
	address1: PropTypes.string,
	address2: PropTypes.string,
	city: PropTypes.string,
	id: PropTypes.string,
	location: PropTypes.object,
	name: PropTypes.string,
	phone: PropTypes.string,
	state: PropTypes.string,
	tracking: TrackingPropType,
	zip: PropTypes.string,
}

export default track({ timestamp: stampTime() }, { dispatch: data => eventDispatch(data) })(HeaderInfoLeft)
