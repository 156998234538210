import styled from '@emotion/styled'
import { MQ, SPACERS, COLORS } from 'utils/styleHelpers'

export const Drawer = styled.div`
	background: ${COLORS.LIGHT_GRAY};
	box-sizing: border-box;
	color: #fff;
	height: ${({ isOpen }) => (isOpen ? `480px` : `10vh`)};
	overflow-y: hidden;
	overflow-x: auto;
	bottom: 0;
	padding: ${SPACERS.M};
	position: fixed;
	transition: background 0.5s, height 300ms cubic-bezier(0.17, 0.04, 0.03, 0.94);
	left: 0;
	${MQ.L} {
		box-shadow: 4px 1px 10px -5px rgba(0, 0, 0, 0.75);
		top: ${({ isOpen }) => (isOpen ? `0` : `-33%`)};
		width: 30%;
	}
	z-index: 1;
	.dark & {
		background: ${COLORS.DARK_BLACK};
	}
	width: 100%;
`
