import styled from '@emotion/styled'
import { Flex, Box } from '@rebass/emotion'
import { COLORS, SPACERS, FONT_SIZES } from 'utils/styleHelpers'

export const AdCardWrapper = styled(Flex)`
	border-radius: 2px;
	margin: ${SPACERS.M};
`
export const AdHeader = styled(Box)`
	background: ${COLORS.GREEN};
	border-radius: 3px;
	color: ${COLORS.WHITE};
	text-transform: uppercase;
	text-align: center;
	font-size: ${FONT_SIZES.H8};
	padding: ${SPACERS.S};
`
export const AdBody = styled(Box)`
	background: ${COLORS.WHITE};
	padding-left: ${SPACERS.M};
	padding-right: ${SPACERS.M};
	padding-top: ${SPACERS.S};
	padding-bottom: ${SPACERS.S};
	transition: background 0.5s;
	.dark & {
		background: ${COLORS.MEDIUM_GRAY};
	}
`
export const AdIconBox = styled(Box)`
	margin-left: ${SPACERS.M};
	margin-right: ${SPACERS.M};
`
export const AdIcon = styled.div`
	background: ${COLORS.DARK_BLACK};
	border-radius: 4px;
	color: ${COLORS.WHITE};
	font-size: ${FONT_SIZES.H9};
	padding-left: 4px;
	padding-right: 4px;
	text-align: center;
`
export const AdTitle = styled(Box)`
	color: ${COLORS.GREEN};
	font-size: ${FONT_SIZES.H5};
	font-weight: bold;
	transition: color 0.5s font-weight 0.5s;
	.dark & {
		color: ${COLORS.WHITE};
		font-weight: normal;
	}
`

export const StarRatingBox = styled(Box)`
	margin-left: ${SPACERS.M};
	margin-right: ${SPACERS.M};
`

export const RatingDetails = styled.span`
	color: ${COLORS.YELLOW};
	font-size: ${FONT_SIZES.H7};
	font-weight: bold;
`
export const InfoIconBox = styled(Box)`
	padding-right: 2px;
	color: ${COLORS.GREEN} !important;
`
