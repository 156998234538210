/* eslint-disable max-len */
import React, { useContext, useLayoutEffect } from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { Flex, Box } from '@rebass/emotion'
import LinkWrapper from 'components/LinkWrapper'
import { SelectTaxonomyIcon } from 'components/Iconography'
import { getLink } from 'utils/urlHelpers'
import { COLORS, SPACERS } from 'utils/styleHelpers'
import { TRACKING_ACTIONS, EVENT_TYPES, NODE_TYPES } from 'utils/constants'
import WithMenuItemMetaData from './WithMenuItemMetaData'
import AnimationText from 'components/AnimationText'
import {
	DetailsContainer,
	DetailsFirstBox,
	DetailsFirstInnerBox,
	DetailsFirstInnerDispensaryBox,
	DetailsSecondBox,
	PricesContainer,
	PricesBox,
	ProductName,
	ReserveNow,
} from './styles'
import { SessionContext } from 'components/AuthContext'

const InventoryItemRowDetails = ({ index, menuListItem }) => {
	const { nodeType, selectedProductId, inventoryItemsChanging, setInventoryItemsChanging } = useContext(
		WithMenuItemMetaData
	)
	const { sessionID, uid, userIp, userLocation, version } = useContext(SessionContext)
	const isInventoryUpdated = menuListItem
		? inventoryItemsChanging.filter(f => f === menuListItem.id).length > 0
		: false

	useLayoutEffect(() => {
		if (isInventoryUpdated) {
			setTimeout(() => {
				setInventoryItemsChanging(inventoryItemsChanging.filter(i => i !== menuListItem.id))
			}, 1500)
		}
	}, [inventoryItemsChanging])

	return (
		<>
			{menuListItem && (
				<DetailsContainer
					flexDirection="column"
					alignItems="center"
					currentId={menuListItem.id}
					selectedProductId={selectedProductId}
					isInventoryUpdated={isInventoryUpdated}
				>
					<DetailsFirstBox nodetype={menuListItem.nodeType}>
						<Flex flexDirection="column">
							<Box
								alignSelf="center"
								css={css`
									padding: ${SPACERS.L};
								`}
							>
								<SelectTaxonomyIcon
									width={50}
									height={50}
									category={menuListItem.category}
									index="inventory"
								/>
							</Box>
							<ProductName alignSelf="center" flex="1" nodeType={nodeType}>
								{menuListItem.productName}
							</ProductName>
							{/* <p>{menuListItem.id}</p> */}
							<DetailsFirstInnerBox alignSelf="center">
								<Flex
									css={css`
										width: 100%;
									`}
								>
									{nodeType === NODE_TYPES.STRAINS && (
										<LinkWrapper
											tracker={{
												action: TRACKING_ACTIONS.STRAIN_PRODUCT_SELECT_ACTION,
												event: EVENT_TYPES.WR_STRAIN_PRODUCT_SELECT,
												nodeID: menuListItem.id,
												nodeType: menuListItem.category.toLowerCase(),
												nodeLabel: menuListItem.productName,
												nodeState: menuListItem.state,
												nodeCity: menuListItem.city,
												nodeZip: menuListItem.zip,
												strainCategory: menuListItem.category,
												sessionID,
												uid,
												userIp,
												userLocation,
												version,
											}}
											state={{ productId: menuListItem.id, resetMenu: true }}
											to={`${getLink(
												{
													state: menuListItem.state,
													city: menuListItem.city,
													slug: menuListItem.slug,
												},
												NODE_TYPES.DISPENSARIES
											)}`}
										>
											<DetailsFirstInnerDispensaryBox>
												<Flex flexDirection="column">
													<Box
														css={css`
															font-weight: bold;
															color: ${COLORS.BLACK};
															transition: color 0.5s;
															.dark & {
																color: ${COLORS.WHITE};
															}
														`}
													>
														{menuListItem.dispensaryName}
													</Box>
													<Box
														css={css`
															color: ${COLORS.BLACK};
															transition: color 0.5s;
															.dark & {
																color: ${COLORS.WHITE};
															}
														`}
													>
														{menuListItem.city} {menuListItem.state} {menuListItem.zip}
													</Box>
												</Flex>
											</DetailsFirstInnerDispensaryBox>
										</LinkWrapper>
									)}
								</Flex>
							</DetailsFirstInnerBox>
						</Flex>
					</DetailsFirstBox>
					<DetailsSecondBox nodetype={nodeType}>
						{menuListItem.prices.length > 0 && (
							<PricesContainer alignItems="center" flexDirection="column">
								{menuListItem.prices.map((p, priceIndex) => (
									<PricesBox
										key={p.id}
										priceindex={priceIndex}
										priceslength={menuListItem.prices.length}
									>
										{isInventoryUpdated ? (
											<>
												$<AnimationText>{parseFloat(p.price).toFixed(2)}</AnimationText> -{' '}
												{p.uomText}
											</>
										) : (
											<>
												${parseFloat(p.price).toFixed(2)} - {p.uomText}
											</>
										)}
									</PricesBox>
								))}
							</PricesContainer>
						)}
					</DetailsSecondBox>
					<Box
						css={css`
							width: 100%;
						`}
					>
						<ReserveNow>Reserve Now</ReserveNow>
					</Box>
				</DetailsContainer>
			)}
		</>
	)
}

InventoryItemRowDetails.propTypes = {
	index: PropTypes.number,
	menuListItem: PropTypes.object,
	nodeId: PropTypes.string,
	nodeType: PropTypes.string,
	selectedProductId: PropTypes.string,
}
InventoryItemRowDetails.defaultProps = {
	index: null,
	selectedProductId: null,
}

export default InventoryItemRowDetails
