import { bool, func, node } from 'prop-types'
import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { COLORS, FONT_SIZES, SPACERS } from 'utils/styleHelpers'

const activeTabBorder = css`
	border-bottom: ${SPACERS.S} solid ${COLORS.GREEN};
`

const DefaultTabItem = styled.div`
	border-bottom: ${SPACERS.S} solid transparent;
	color: ${({ isDisabled }) => (!isDisabled ? COLORS.GREEN : COLORS.LIGHT_GRAY)};
	cursor: pointer;
	font-size: ${FONT_SIZES.H6};
	padding-top: ${SPACERS.L};
	padding-bottom: ${SPACERS.L};
	width: 48%;
	text-align: center;
	text-transform: uppercase;
	transition: background 0.5s, border-bottom 0.3s, color 0.3s;
	${({ isActive }) => {
		if (isActive) {
			return `
				background: ${COLORS.LIGHT_GRAY};		
				color: ${COLORS.GREEN};
				transition: background 0.5s;
				.dark & {
					background: ${COLORS.BLACK};
				}
			`
		}
		return `
			background: ${COLORS.LIGHT_GRAY};
			color: ${COLORS.DARK_GRAY};
			transition: background 0.5s;
			.dark & {
				background: ${COLORS.BLACK};
				color: ${COLORS.WHITE};
			}
		`
	}}
	&:hover {
		${activeTabBorder}
	}
	${({ isActive }) => isActive && activeTabBorder}
`

const propTypes = {
	_isActive: bool,
	_onSelect: func,
	children: node.isRequired,
	disabled: bool,
}

function Tab({ children, disabled = false, _isActive, _onSelect }) {
	return (
		<DefaultTabItem isActive={_isActive} isDisabled={disabled} onClick={disabled ? null : _onSelect}>
			{children}
		</DefaultTabItem>
	)
}

Tab.propTypes = propTypes

export default Tab
