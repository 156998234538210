import styled from '@emotion/styled'
import { FONT_SIZES, MQ, SPACERS } from 'utils/styleHelpers'
import { Box } from '@rebass/emotion'

export const InfoBox = styled(Box)`
	margin-top: ${SPACERS.S};
`
export const InfoValue = styled.span`
	font-size: ${FONT_SIZES.H6};
	${MQ.L} {
		font-size: ${FONT_SIZES.H5};
	}
`
