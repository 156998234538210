import { Box } from '@rebass/emotion'
import styled from '@emotion/styled'
import { SPACERS } from 'utils/styleHelpers'

export const HeaderInfoLeftBottomBox = styled(Box)`
	margin-left: ${SPACERS.S};
	margin-right: ${SPACERS.S};
`

export const HeaderInfoLeftTopBox = styled(Box)`
	margin-right: ${SPACERS.S};
`
