import styled from '@emotion/styled'
import { COLORS, SPACERS, MQ, MAX_WIDTH } from 'utils/styleHelpers'

export const StyledOverlay = styled.div`
	background: ${COLORS.WHITE};
	border-radius: 4px;
	box-shadow: 0 0 0 60vmax rgba(0, 0, 0, 0.25), 0 0 32px rgba(0, 0, 0, 0.25);
	left: 50%;
	max-width: 100vw;
	max-height: 100vh;
	overflow: auto;
	padding: 16px;
	position: fixed;
	top: 50%;
	transform: translate(-50%, -50%);
	transition: background 0.5s;
	width: ${MAX_WIDTH.M};
	height: calc(80vh - ${SPACERS.HEADER});
	${MQ.L} {
		width: ${MAX_WIDTH.L};
		height: calc(63vh - ${SPACERS.HEADER});
	}
	.dark & {
		background: ${COLORS.MEDIUM_GRAY};
	}
`
export const Blocker = styled.div`
	height: 100vh;
	width: 100vw;
	position: fixed;
	top: 0;
	z-index: 4;
`

export const CloseModalButton = styled.button`
	background: none;
	padding: 0;
	border: none;
	position: absolute;
	left: 570px;
	color: ${COLORS.GREEN};
`
