// external dependencies
import { node, number } from 'prop-types'
import React, { Children, useEffect, useState } from 'react'

import Tab from 'components/Tabs/Tab'
import TabList from 'components/Tabs/TabList'
import TabPanel from 'components/Tabs/TabPanel'
import TabPanels from 'components/Tabs/TabPanels'

function Tabs({ activeIndex = 0, children }) {
	const [currentIndex, setCurrentIndex] = useState(activeIndex || 0)

	useEffect(() => {
		setCurrentIndex(activeIndex)
	}, [activeIndex])

	const tabChildren = Children.map(children, child => {
		if (child.type.displayName === TabPanels.displayName) {
			return React.cloneElement(child, {
				_activeIndex: currentIndex,
			})
		}

		if (child.type.displayName === TabList.displayName) {
			return React.cloneElement(child, {
				_activeIndex: currentIndex,
				_onTabSelect: index => setCurrentIndex(index),
			})
		}

		return child
	})
	return <div>{tabChildren}</div>
}

Tabs.propTypes = {
	activeIndex: number,
	children: node,
}
export default Tabs

export { Tab, TabList, TabPanel, TabPanels }
