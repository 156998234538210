import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { MdClear } from 'react-icons/md'

import { StyledOverlay, Blocker, CloseModalButton } from './styles'

function Modal({ children, isDisplayed, onClose }) {
	const [isShowingOverlay, setIsShowingOverlay] = useState(true)
	useEffect(() => {
		setIsShowingOverlay(isDisplayed)
	}, [isDisplayed])

	const onCancelClick = event => {
		event.stopPropagation()
	}

	if (!isShowingOverlay) {
		return null
	}

	const onCloseModal = event => {
		setIsShowingOverlay(false)
		onClose()
	}

	return (
		<Blocker>
			<StyledOverlay onClick={onCancelClick}>
				<CloseModalButton onClick={onCloseModal}>
					<MdClear />
				</CloseModalButton>
				{children}
			</StyledOverlay>
		</Blocker>
	)
}

Modal.propTypes = {
	children: PropTypes.object,
	isDisplayed: PropTypes.bool,
	onClose: PropTypes.func,
}

export default Modal
