import React from 'react'
import PropTypes from 'prop-types'
import InventoryItemRowDetails from './inventoryItemRowDetails'

const InventoryItemRow = ({ index, data }) => {
	return <InventoryItemRowDetails index={index} menuListItem={data} />
}

InventoryItemRow.propTypes = {
	index: PropTypes.number,
	menuListItem: PropTypes.object,
	nodeId: PropTypes.string,
	nodeType: PropTypes.string,
	selectedProductId: PropTypes.string,
	sessionID: PropTypes.string,
	uid: PropTypes.string,
	version: PropTypes.string,
	data: PropTypes.object,
}
InventoryItemRow.defaultProps = {
	index: null,
	selectedProductId: null,
}

export default InventoryItemRow
