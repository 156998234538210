import React from 'react'
import PropTypes from 'prop-types'
import useTween from 'hooks/useTween'

function AnimationText({ children, ...props }) {
	const progress = useTween(2000)
	const value = parseFloat(Math.round(progress * children)).toFixed(2)
	return <span {...props}>{value}</span>
}
AnimationText.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node
	])
}
export default AnimationText
