import React from 'react'
import PropTypes from 'prop-types'
import { NoDisplayMobile } from 'components/NoDisplay'
import { InfoValue, InfoBox } from './styles'

function HeaderInfoAboutLarge({ address1, address2, city, state, zip }) {
	return (
		<NoDisplayMobile>
			<InfoBox>
				<InfoValue>
					{address1} {address2} {city}, {state} {zip}
				</InfoValue>
			</InfoBox>
		</NoDisplayMobile>
	)
}

HeaderInfoAboutLarge.propTypes = {
	address1: PropTypes.string,
	address2: PropTypes.string,
	city: PropTypes.string,
	state: PropTypes.string,
	zip: PropTypes.string,
}

export default HeaderInfoAboutLarge
