/* eslint-disable */
import React from 'react'

export default function TabPanel({ children, _isActive }) {
	// if we're not using the "shouldStayMounted" for TabPanels setup just return as is
	if (typeof _isActive === 'undefined') {
		return <div>{children}</div>
	}

	return <div style={{ display: _isActive ? 'block' : 'none' }}>{children}</div>
}
