import styled from '@emotion/styled'
import { COLORS, SPACERS, FONT_SIZES, MQ, shadeColor2 } from 'utils/styleHelpers'
import { Flex, Box } from '@rebass/emotion'
import { NODE_TYPES } from 'utils/constants'

export const LazyLoadWrapper = styled(Box)`
	${({ islazyloaded }) => islazyloaded === 'true' && `height: 80vh;`}
	overflow-y: scroll;
`

export const MenuContainer = styled(Flex)``
export const FilterButtonGroup = styled(Flex)`
	background: ${COLORS.WHITE};
	padding: ${SPACERS.M};
	border-bottom: 1px solid ${COLORS.LIGHT_GRAY};
	transition: background 0.5s, border-bottom 0.5s;
	.dark & {
		border-bottom: none;
		background: ${COLORS.MEDIUM_GRAY};
	}
`

export const FilterButton = styled.button`
	border: 1px solid ${({ isSelected }) => (isSelected ? COLORS.GREEN : COLORS.MEDIUM_LIGHT_GRAY)};
	background: ${({ isSelected }) => (isSelected ? COLORS.GREEN : COLORS.LIGHT_GRAY)};
	color: ${({ isSelected }) => (isSelected ? '#fff' : COLORS.BLACK)};
	cursor: pointer;
	flex-basis: 30%;
	border-radius: 3px;
	padding-top: ${SPACERS.M};
	padding-bottom: ${SPACERS.M};
	padding-left: ${SPACERS.L};
	padding-right: ${SPACERS.L};
	font-size: ${FONT_SIZES.H6};
	font-weight: ${({ isSelected }) => (isSelected ? `bold` : `0`)};
	.dark & {
		background: ${({ isSelected }) => (isSelected ? COLORS.GREEN : COLORS.BLACK)};
		border: 1px solid ${({ isSelected }) => (isSelected ? COLORS.GREEN : COLORS.MEDIUM_LIGHT_GRAY)};
		color: ${({ isSelected }) => (isSelected ? '#fff' : COLORS.WHITE)};
	}
	${MQ.L} {
		margin-bottom: ${SPACERS.M};
		margin-left: ${SPACERS.M};
		margin-right: ${SPACERS.M};
		margin-top: ${SPACERS.M};
		padding-top: ${SPACERS.M};
		padding-bottom: ${SPACERS.M};
		font-size: ${FONT_SIZES.H5};
		font-weight: bold;
	}
	${MQ.M} {
		margin-bottom: ${SPACERS.M};
		margin-left: ${SPACERS.M};
		margin-right: ${SPACERS.M};
		margin-top: ${SPACERS.M};
		padding-top: ${SPACERS.M};
		padding-bottom: ${SPACERS.M};
		font-size: ${FONT_SIZES.H5};
		font-weight: bold;
	}
`
export const DetailsContainer = styled(Flex)`
	margin: ${SPACERS.M};
	border-radius: 4px;
	transition: background 0.5s;
	background: ${({ selectedProductId, currentId }) =>
		selectedProductId && currentId === selectedProductId ? shadeColor2(COLORS.GREEN, 0.5) : COLORS.WHITE};
	.dark & {
		background: ${({ selectedProductId, currentId }) =>
			selectedProductId && currentId === selectedProductId ? shadeColor2(COLORS.GREEN, 0.5) : COLORS.DARK_GRAY};
	}
	${({ isInventoryUpdated }) => {
		if (isInventoryUpdated) {
			return `
				@keyframes pulse {
					0%, 100% {
						filter: none;
					}
					50% {
						filter: blur(3px);
					}
				}
				animation: pulse 1.5s ease infinite alternate;
				
			`
		}
	}}
`
export const DetailsFirstBox = styled(Box)`
	width: 100%;
`
export const DetailsFirstInnerBox = styled(Box)`
	font-size: ${FONT_SIZES.H6};
	flex-grow: 1;
	${MQ.M} {
		font-size: ${FONT_SIZES.H4};
	}
`
export const DetailsFirstInnerDispensaryBox = styled(Box)`
	font-size: ${FONT_SIZES.H5};
	padding ${SPACERS.M};
	color: ${COLORS.WHITE};
`
export const DetailsSecondBox = styled(Box)`
	align-self: center;
	width: 100%;
	${MQ.L} {
		flex-basis: ${({ nodetype }) => (nodetype === NODE_TYPES.DISPENSARIES ? `40%` : `0%`)};
	}
`
export const PricesContainer = styled(Flex)`
	background: ${COLORS.DARK_BLACK};
	border-radius: 1px;
	color: ${COLORS.WHITE};
	${MQ.L} {
		margin-top: none;
	}
`
export const PricesBox = styled(Box)`
	border-bottom: ${({ priceindex, priceslength }) =>
		priceindex !== priceslength - 1 ? `1px solid ${COLORS.LIGHT_GRAY}` : `0px`};
	font-size: ${FONT_SIZES.H7};
	font-weight: bold;
	padding: ${SPACERS.M};
	${MQ.L} {
		font-size: ${FONT_SIZES.H5};
	}
`
export const ProductName = styled(Box)`
	font-size: ${FONT_SIZES.H5};
	font-weight: 600;
	padding: ${SPACERS.L};
	color: ${COLORS.WHITE};
	text-align: center;
	background: ${COLORS.GREEN};
	width: 100%;
	text-transform: capitalize;
`
export const ReserveNow = styled.button`
	background: ${COLORS.RED};
	border: 0;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	color: ${COLORS.WHITE};
	font-size: ${FONT_SIZES.H6};
	font-weight: bold;
	padding: ${SPACERS.M};
	text-align: center;
	width: 100%;
`
export const NoMenuItems = styled.div`
	background: ${COLORS.PALE_GREEN};
	font-weight: 500;
	margin-bottom: ${SPACERS.L};
	padding: ${SPACERS.L};
	text-align: center;
	transition: background 0.5s;
	.dark & {
		background: ${COLORS.MEDIUM_GRAY};
	}
`
export const Emoji = styled.span`
	font-size: 30px;
	line-height: 30px;
`
