import React from 'react'
import PropTypes from 'prop-types'
import { InfoBox, InfoValue, InfoLabel } from './styles'

function HeaderInfoRightTop({ hours }) {
	return (
		<InfoBox>
			{hours && (
				<>
					<InfoLabel>Hours</InfoLabel>
					{`:  `}
					<InfoValue>{hours}</InfoValue>
				</>
			)}
		</InfoBox>
	)
}

HeaderInfoRightTop.propTypes = {
	hours: PropTypes.string,
}

export default HeaderInfoRightTop
