import { Flex, Box } from '@rebass/emotion'
import styled from '@emotion/styled'
import { COLORS, FONT_SIZES } from 'utils/styleHelpers'

export const AdWrapper = styled(Flex)`
	background: ${COLORS.LIGHT_GRAY};
	box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
	transition: background 0.5s, box-shadow 0.5s;
	width: 100%;
	.dark & {
		background: ${COLORS.DARK_GRAY};
	}
`
export const CloseButton = styled.button`
	background: none;
	padding: 0;
	border: none;
	position: absolute;
	left: 350px;
	color: ${COLORS.GREEN};
`

export const AdHeaderBox = styled(Box)`
	width: 100%;
`
export const AdHeader = styled.span`
	font-size: ${FONT_SIZES.H5};
	font-weight: bold;
	transition: font-weight 0.5s, color 0.5s;
	.dark & {
		color: ${COLORS.LIGHT_GRAY};
		font-weight: normal;
	}
`
export const AdCardBox = styled(Box)`
	width: 100%;
`
