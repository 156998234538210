import React, { useEffect, useState } from 'react'
import { Flex, Box } from '@rebass/emotion'
import PropTypes from 'prop-types'
import InventoryItemRowDetails from 'components/InventoryMenu/inventoryItemRowDetails'

// only supporting two columns right now...TODO: make better to support n number of columns
function isOdd(num) {
	return !!(num % 2)
}

function StaticInventoryMenu({ menuItems }) {
	// const [rowItems, setRowItems] = useState([])

	// useEffect(() => {
	// 	if (menuItems) {
	// 		setRowItems(menuItems)
	// 	}
	// }, [menuItems])

	// const rows = menuItems
	// 	.map((item, index) => {
	// 		// if (isOdd(index)) {
	// 		return (
	// 			<Flex key={index}>
	// 				<Box>
	// 					<InventoryItemRowDetails index={index} menuListItem={menuItems[index]} />
	// 				</Box>
	// 				<Box>
	// 					<InventoryItemRowDetails index={index} menuListItem={menuItems[index + 1]} />
	// 				</Box>
	// 			</Flex>
	// 		)
	// 		// } else {
	// 		// 	return null
	// 		// }
	// 	})
	// 	.filter(f => f || false)
	// console.log(rows)
	return menuItems.map((item, index) => {
		if (isOdd(index)) {
			return (
				<Flex key={index}>
					<Box flexBasis={'50%'}>
						<InventoryItemRowDetails index={index} menuListItem={menuItems[index]} />
					</Box>
					<Box flexBasis={'50%'}>
						<InventoryItemRowDetails index={index} menuListItem={menuItems[index + 1]} />
					</Box>
				</Flex>
			)
		} else {
			return null
		}
	})
}

StaticInventoryMenu.propTypes = {
	menuItems: PropTypes.array,
}

export default StaticInventoryMenu
